<template>
    <li class="department__item" v-bind:class="{'open': active}">
        <div class="department__row" @click="toggleActive()">
            <div
                v-show="item.children && item.position && (item.children.length > 0 || item.position.length > 0)"
                class="department__row_arrow"
            >
                <span class="department__icon department__icon_bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                        <path d="M1 1L5 5L9 1" stroke="#9A9A9A"></path>
                    </svg>
                </span>
                <span class="department__icon department__icon_top">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                        <path d="M1 5L5 1L9 5" stroke="#9A9A9A"></path>
                    </svg>
                </span>
            </div>

            <span class="department__row_name">
                {{ item.name }}
            </span>

            <label>
                <input
                    class="access_departament"
                    type="checkbox"
                    :data-id="identifier(item)"
                    :checked="item.checked"
                    @click="toggleCheckbox(item)"
                >
                <div class="department__row_button">
                    <i v-if="item.checked" class="fas fa-check"></i>
                    <i v-else class="fas fa-plus"></i>
                </div>
            </label>
        </div>

        <ul class="workers__department department" v-if="!item.is_position">
            <div v-if="item.position && item.position.length > 0">
                <node-permission-tree-structure
                    v-for="child in item.position"
                    :key="child.id"
                    :item="Object.assign(child, { parent: item.id })"
                    :is_position="true"
                    @buildStructTree="buildStructTree"
                    @buildPositionTree="buildPositionTree"
                ></node-permission-tree-structure>
            </div>

            <div v-if="item.children && item.children.length > 0" >
                <node-permission-tree-structure
                    v-for="child in item.children"
                    :key="child.id"
                    :item="Object.assign(child, { parent: item.id })"
                    @buildStructTree="buildStructTree"
                    @buildPositionTree="buildPositionTree"
                ></node-permission-tree-structure>
            </div>
        </ul>
    </li>
</template>

<script>
    export default {
        name: 'nodePermissionTreeStructure',
        components: {},
        props: {
            is_position: {
                type: Boolean,
                default: false
            },
            item: {
                type: Object
            }
        },
        data() {
            return {
                active: false
            };
        },
        methods: {
            identifier(item) {
                let name = '';
                if (this.is_position) {
                    item.is_position = true;
                    name = 'position-' + item.id;
                } else {
                    item.is_position = false;
                    name = 'departament-' + item.id;
                }
                return name;
            },
            buildPositionTree(itemId, checked) {
                this.$emit('buildPositionTree', itemId, checked);
            },
            buildStructTree(itemId, checked) {
                this.$emit('buildStructTree', itemId, checked);
            },
            toggleActive() {
                this.active = !this.active;
            },
            toggleCheckbox(item) {
                item.checked = !item.checked;

                if (item.is_position) {
                    this.buildPositionTree(item, item.checked);
                } else {
                    this.buildStructTree(item, item.checked);
                }
            }
        },
        mounted() {
            console.log('this.item: ', this.item);
        }
    };
</script>

<style>

</style>
