<template>
    <div
        :class="[
            'sidebar-link',
            `sidebar-link--${modeType}`,
            { 'sidebar-link--draggable': draggable },
            { 'sidebar-link--draft': isEditorMaterial && !isPublished }
        ]"
    >
        <div class="sidebar-link__row">
            <div class="sidebar-link__col sidebar-link__col--left">
                <span
                    v-if="modeType === 'editing' && draggable"
                    class="sidebar-link__label"
                >
                    Название материала(<b>#{{data.id}}</b>)
                </span>

                <router-link
                    v-if="external.status === false"
                    tag="a"
                    class="sidebar-link-router"
                    :to="data.link"
                    @click.native="$store.dispatch('sidebar/setSidebarOpened', false);"
                >
                    <div class="sidebar-link-router__row">
                        <div class="sidebar-link-router__col sidebar-link-router__col--left">
                            <div
                                class="sidebar-link-figure"
                                @click="$emit('changeIcon', data)"
                            >
                                <div
                                    v-if="data.required || data.emoji"
                                    :title="(data.required) ? `Материал обязателен для изучения` : ``"
                                    class="sidebar-link-figure__emoji"
                                >
                                    <icon
                                        v-if="data.required"
                                        width="12"
                                        height="14"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M0 0.5C0 0.223857 0.223858 0 0.5 0H11.1667C11.4428 0 11.6667 0.223858 11.6667 0.5V13.5C11.6667 13.7761 11.4428 14 11.1667 14H0.5C0.223858 14 0 13.7761 0 13.5V0.5ZM5.83333 8.16667C5.189 8.16667 4.66667 7.64433 4.66667 7V3.5C4.66667 2.85567 5.189 2.33333 5.83333 2.33333C6.47766 2.33333 7 2.85567 7 3.5V7C7 7.64433 6.47767 8.16667 5.83333 8.16667ZM5.83333 9.625C5.189 9.625 4.66667 10.1473 4.66667 10.7917C4.66667 11.436 5.189 11.9583 5.83333 11.9583C6.47767 11.9583 7 11.436 7 10.7917C7 10.1473 6.47767 9.625 5.83333 9.625Z"
                                            fill="#FBC04F"
                                        />
                                    </icon>

                                    <emoji
                                        v-else
                                        :emoji="data.emoji"
                                        set="apple"
                                        :size="16"
                                    />
                                </div>

                                <img
                                    v-else-if="imgUrl"
                                    class="sidebar-link-figure__icon"
                                    :src="imgUrl"
                                />
                            </div>

                            <div class="sidebar-link-text__wrapper">
                                <div
                                    class="sidebar-link-text__content"
                                    :title="data.name"
                                >{{ data.name }}</div>
                            </div>
                        </div>

                        <div class="sidebar-link-router__col sidebar-link-router__col--right">
                            <trigger-add
                                v-if="triggerAdd.visible"
                                :is-to="triggerAdd.to"
                                @add="$emit('add', data)"
                                class="sidebar-link__trigger"
                            />
                        </div>
                    </div>
                </router-link>

                <a
                    v-else
                    :href="data.link"
                    :target="external.target"
                    class="sidebar-link-router"
                >
                    <div class="sidebar-link-router__row">
                        <div class="sidebar-link-router__col sidebar-link-router__col--left">
                            <div
                                class="sidebar-link-figure"
                                :title="(data.required) ? `Материал обязателен для изучения` : ``"
                                @click.prevent="$emit('changeIcon', data)"
                            >
                                <div
                                    v-if="data.required || data.emoji"
                                    class="sidebar-link-figure__emoji"
                                >
                                    <icon
                                        v-if="data.required"
                                        width="12"
                                        height="14"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M0 0.5C0 0.223857 0.223858 0 0.5 0H11.1667C11.4428 0 11.6667 0.223858 11.6667 0.5V13.5C11.6667 13.7761 11.4428 14 11.1667 14H0.5C0.223858 14 0 13.7761 0 13.5V0.5ZM5.83333 8.16667C5.189 8.16667 4.66667 7.64433 4.66667 7V3.5C4.66667 2.85567 5.189 2.33333 5.83333 2.33333C6.47766 2.33333 7 2.85567 7 3.5V7C7 7.64433 6.47767 8.16667 5.83333 8.16667ZM5.83333 9.625C5.189 9.625 4.66667 10.1473 4.66667 10.7917C4.66667 11.436 5.189 11.9583 5.83333 11.9583C6.47767 11.9583 7 11.436 7 10.7917C7 10.1473 6.47767 9.625 5.83333 9.625Z"
                                            fill="#FBC04F"
                                        />
                                    </icon>

                                    <emoji
                                        v-else
                                        :emoji="data.emoji"
                                        :size="16"
                                        set="apple"
                                    />
                                </div>

                                <img
                                    v-else-if="imgUrl"
                                    class="sidebar-link-figure__icon"
                                    :src="imgUrl"
                                />
                            </div>

                            <div class="sidebar-link-text__wrapper">
                                <div
                                    class="sidebar-link-text__content"
                                    :title="data.name"
                                >
                                    {{ data.name }}
                                </div>
                            </div>
                        </div>

                        <div class="sidebar-link-router__col sidebar-link-router__col--right">
                            <trigger-add
                                v-if="triggerAdd.visible"
                                :is-to="triggerAdd.to"
                                @add="$emit('add', data)"
                                class="sidebar-link__trigger"
                            />
                        </div>
                    </div>
                </a>
            </div>

            <div
                v-if="modeType === 'editing' && draggable"
                class="sidebar-link__col sidebar-link__col--right"
            >
                <span class="sidebar-link__label">ID род.раздела</span>
                <input
                    type="number"
                    min="23"
                    placeholder="id"
                    title="Введите id родительского раздела"
                    class="sidebar-link__field sidebar-link__field--field"
                    :value="idParent"
                    @input="$emit('changeParent', Object.assign({newParent: $event.target.value, oldParent: idParent}))"
                >
            </div>
        </div>
    </div>
</template>

<script>
    import { Emoji } from 'emoji-mart-vue';
    import Icon from '@/components/icon/Icon';
    import IconMaterial from 'assets/img/favicons/material.svg';
    import IconRubric from 'assets/img/favicons/rubric.svg';
    import TriggerAdd from '@/components/sidebar/navigation/TriggerAdd';
    import { mapGetters } from 'vuex';

    export default {
        name: 'NavLink',
        components: {
            Icon,
            TriggerAdd,
            Emoji
        },

        props: {
            data: {
                type: Object,
                default: () => {}
            },

            treeNode: {
                type: Object,
                default: () => {}
            },

            external: {
                type: Object,
                default: () => {
                    return {
                        status: false,
                        target: '_self'
                    };
                }
            },

            triggerAdd: {
                type: Object,
                default: () => {
                    return {
                        visible: false
                    };
                }
            },

            draggable: {
                type: Boolean,
                default: false
            },

            modeType: {
                type: String
            }
        },

        data() {
          return {
              isPublished: this.$props.data.published === undefined ? true : this.$props.data.published,
              idParent: this.$props.treeNode && this.$props.treeNode.parent.data.id ? this.$props.treeNode.parent.data.id : 23
          };
        },

        computed: {
            ...mapGetters('default_data', ['isEditorMaterial']),

            imgUrl() {
                let result = '';

                if (this.data.icon) {
                    result = require('assets/img/favicons/sidebar/' + this.data.icon);
                } else {
                    if (this.data.is_type === 'material') {
                        result = IconMaterial;
                    }

                    if (this.data.is_type === 'rubric') {
                        result = IconRubric;
                    }
                }

                return result;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "#sass/v-style";

    .sidebar-link {
        color: inherit;
        width: 100%;
        min-width: 0px;
        &:hover {
            .sidebar-link__trigger {
                opacity: 1;
            }
        }

        &__row {
            margin: -5px;
            display: flex;
        }

        &__col {
            margin: 5px;
            &--left {
                flex-grow: 1;
            }
        }

        &__label {
            color: #636363;
            font-size: 12px;
            display: block;
            margin: 0 0 2px;
        }

        &-router {
            color: inherit;
            &__row {
                display: flex;
                align-items: center;
            }

            &__col {
                display: flex;
                align-items: center;
                &--left {
                    flex-grow: 1;
                }

                &--right {
                    margin-left: 10px;
                }
            }

            .sidebar-link--draft & {
                opacity: 0.5;
            }

            .edit-tree & {
                opacity: 1;
            }
        }

        &-figure {
            &__icon {
                $icon-size: 13px;

                width: $icon-size;
                height: $icon-size;
                display: block;
                margin-right: 10px;
            }

            &__emoji {
                $icon-size: 16px;

                width: $icon-size;
                height: $icon-size;
                margin-right: 10px;

                display: flex;
                align-items: center;
                justify-content: center;
                .emoji-mart-emoji {
                    display: flex !important;
                    font-size: 15px;
                    line-height: 15px;
                }
            }
        }

        &-text {
            &__wrapper {
                min-width: 0;
                max-width: calc(var(--aside-width) - 130px); // это нужно для того, чтобы ограничить текст для горизонтального скролла
            }

            &__content {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &__trigger {
            opacity: 0;
        }

        &__field {
            width: 96px;
            padding: 2px 5px;
            border: 1px solid #ddd;
        }

        .el-submenu--editing & {
            &:not(.sidebar-link--title) {
                padding: 10px 20px;
                border-radius: 6px;

                border-width: 1px;
                border-style: solid;
                border-color: #989cae;
            }
        }
    }
</style>
