var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-menu',{staticClass:"sidebar__nav",on:{"open":_vm.onSubMenuOpen}},[_vm._l((_vm.navElements),function(navItem,navIndex){return _c('div',{key:navIndex},[(navItem.name === 'База знаний')?_c('div',{staticClass:"sidebar__nav-item"},[_c('div',_vm._g({},_vm.isAdmin || _vm.isEditorMaterial ? { 'contextmenu': function ($event) { return _vm.onOpenMaterialContextMenu($event, navItem); } } : {}),[_c('nav-tree-material',{attrs:{"is-data":navItem,"is-index":("" + navIndex),"is-open":_vm.materialSubMenuOpen}})],1)]):(navItem.name === 'Документы')?_c('nav-tree-doc',{attrs:{"is-data":navItem,"is-index":("" + navIndex),"is-open":_vm.docSubMenuOpen}}):_c('el-submenu',{staticClass:"sidebar__nav-item",attrs:{"index":("" + navIndex),"disabled":navItem.children == undefined || navItem.children.length === 0}},[_c('template',{slot:"title"},[_c('nav-link',{attrs:{"data":Object.assign(
                    {
                        name: navItem.name || navItem.title,
                        link: navItem.link,
                    },
                    navItem
                )}})],1),_c('el-menu-item-group',_vm._l((navItem.children),function(navChild,childIndex){return _c('el-menu-item',{key:childIndex,staticClass:"sidebar__nav-item",attrs:{"index":(navIndex + "-" + childIndex)}},[_c('nav-link',{attrs:{"data":Object.assign(
                        {
                            name: navChild.name || navChild.title,
                            link: navChild.link
                        },
                        navChild
                    )}})],1)}),1)],2)],1)}),_c('teleport',{attrs:{"to":"body"}},[_c('context-menu',{ref:"materialContextMenu",attrs:{"elementId":_vm.materialContextMenu.id,"options":_vm.materialContextMenu.options},on:{"option-clicked":_vm.onMaterialContextMenuClick}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }