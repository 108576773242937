<template>
    <div>
        <template v-if='item.type === "user"'>
            <router-link :to="`/auth/profile/${item.id}/?staff=1`">
                <div class="search-item">
                    <div
                        class="search-item__image"
                        :class="{ 'search-item__image--is-emoji': item.emoji }"
                    >
                        <div class="search-item__image-emoji">
                            <img  v-if="item.source.image" class="search-item__image-img user" :src='item.source.image' alt=''>
                            <img v-else class="search-item__image-img user" :src="defaultAvatar" />
                        </div>
                    </div>
                    <div class="search-item__text">
                        <p class="search-item__text-title"
                        >
                            {{item.source.full_name}}
                        </p>
                    </div>
                </div>
            </router-link>
        </template>

        <template v-if='item.type === "document"'>
            <div
                class="search-item__btn"
                :title="item.source.name"
                @click="onDocumentRedirect(item)"
            >
                <div class="search-item">
                    <div
                        class="search-item__image"
                        :class="{ 'search-item__image--is-emoji': item.emoji }"
                    >
                        <div class="search-item__image-emoji">
                            <emoji
                                v-if="item.source.emoji"
                                class='user'
                                set="apple"
                                :size="18"
                                :emoji="item.source.emoji"
                            />

                            <img
                                v-else
                                class="search-item__image-img user"
                                :src="icon"
                            />
                        </div>
                    </div>

                    <div class="search-item__text">
                        <p
                            class="search-item__text-title"
                            :title="item.source.name"
                        >
                            {{ item.source.name }}
                        </p>

                        <div
                            v-if="item.source.type === 2 && item.source.files && item.source.files.length > 0"
                            class="search-item__footer"
                        >
                            Прикреплённые файлы:
                            <div class="search-item__files">
                                <a
                                    v-for="(documentFile, index) in item.source.files"
                                    :key="`${item.id}-file-${index}`"
                                    :href="documentFile.link"
                                    :title="documentFile.file_title"
                                    target="_blank"
                                    class="search-item__file"
                                >
                                    {{documentFile.file_title}}{{item.source.files.length > 1 && index !== (item.source.files.length - 1) ? ',' : ''}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </template>

        <template v-if='item.type === "materialattachment"'>
            <router-link :to="`/knowledge/article/${item.source.material_id}`">
                <div class="search-item">
                    <div
                        class="search-item__image"
                        :class="{ 'search-item__image--is-emoji': item.emoji }"
                    >
                        <div class="search-item__image-emoji">
<!--                            <img  v-if="item.source.emoji" class="search-item__image-img user" :src='item.source.emoji' alt=''>-->

                            <emoji
                                class='user'
                                v-if="item.source.emoji"
                                :emoji="item.source.emoji"
                                set="apple"
                                :size="18"
                            />
                            <img v-else class="search-item__image-img user" :src="icon" />
                        </div>
                    </div>
                    <div class="search-item__text">
                        <p class="search-item__text-title"
                        >
                            {{item.source.file_name}}
                        </p>
                    </div>
                </div>
            </router-link>
        </template>

        <template v-if='item.type === "material"'>
            <router-link :to="`/knowledge/article/${item.id}`">
                <div class="search-item">
                    <div
                        class="search-item__image"
                        :class="{ 'search-item__image--is-emoji': item.emoji }"
                    >
                        <div class="search-item__image-emoji">
                            <emoji
                                v-if="item.source.emoji"
                                :emoji="item.source.emoji"
                                set="apple"
                                :size="18"
                            />
<!--                            <img  v-if="item.source.emoji" class="search-item__image-img user" :src='item.source.emoji' alt=''>-->
                            <img v-else class="search-item__image-img" :src="icon" />
                        </div>
                    </div>
                    <div class="search-item__text">
                        <p
                            v-if="item.highlight.title || item.source.title"
                            class="search-item__text-title"
                            :class="{
                        'is-alone': !item.highlight.text
                    }"
                            v-html="`<span>${item.highlight.title || item.source.title}</span>`"
                        ></p>
                        <p
                            v-if="item.highlight.text || item.source.text"
                            class="search-item__text-subtitle"
                            :class="{
                        'is-alone': !item.highlight.title
                    }"
                            v-html="`<span>${item.highlight.text || item.source.text}</span>`"
                        ></p>
                    </div>
                </div   >
            </router-link>
        </template>

        <template v-if='item.type === "course"'>
            <router-link :to="`/courses/${item.id}`">
                <div class="search-item">
                    <div
                        class="search-item__image"
                        :class="{ 'search-item__image--is-emoji': item.emoji }"
                    >
                        <div class="search-item__image-emoji">
<!--                            <img  v-if="item.source.emoji" class="search-item__image-img user" :src='item.source.emoji' alt=''>-->
                            <emoji
                                class='user'
                                v-if="item.source.emoji"
                                :emoji="item.source.emoji"
                                set="apple"
                                :size="18"
                            />
                            <img v-else class="search-item__image-img user" :src="icon" />
                        </div>
                    </div>
                    <div class="search-item__text">
                        <p class="search-item__text-title"
                        >
                            {{item.source.name}}
                        </p>
                    </div>
                </div>
            </router-link>
        </template>
    </div>
</template>

<script>
    import { Emoji } from 'emoji-mart-vue';
    import Material from 'assets/img/favicons/material.svg';
    import Rubric from 'assets/img/favicons/rubric.svg';
    import defaultAvatar from '@/assets/img/avatars/default_avatar.jpg';
    import studying from '@/assets/img/favicons/sidebar/studying.svg';
    import document from '@/assets/img/documents.svg';

    export default {
        components: { Emoji },
        computed: {
            icon() {
                if (this.item.type === 'material') {
                    return this.Material;
                }
                if (this.item.type === 'user') {
                    return this.defaultAvatar;
                }
                if (this.item.type === 'document' || this.item.type === 'materialattachment') {
                    return this.document;
                }
                if (this.item.type === 'course') {
                    return studying;
                }
                if (this.item.type === 'material') {

                }
                return this.Rubric;
            }
        },
        props: {
            item: { type: Object, required: true }
        },
        data() {
            return {
                Material,
                Rubric,
                defaultAvatar,
                studying,
                document
            };
        },
        methods: {
            onDocumentRedirect(document) {
                const { source } = document;

                if (source.type === 1 && source.link) {
                    window.open(source.link);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    $maxWidthIcons: 18px;
    .is-alone {
        -webkit-line-clamp: 2 !important;
    }
    .search-item {
        width: 100%;
        display: flex;
        border-radius: 4px;
        padding: 5px;
        transition: background-color 200ms ease;
        &:hover {
            background: #e6ebf3;
        }
        &__image {
            width: $maxWidthIcons;
            min-width: $maxWidthIcons;
            justify-content: center;
            display: flex;
            align-items: center;
            &--is-emoji {
                align-items: flex-start;
            }
            &-emoji {
                display: flex;
                align-items: center;
                align-self: flex-start;
            }
            &-img {
                width: 14px;
                height: 14px;
                &.user{
                    width: $maxWidthIcons;
                    height: $maxWidthIcons;
                    border-radius: 50%;
                }
            }
        }
        &__text {
            margin-left: 10px;
            &-title {
                color: #000;
                font-size: 13px;
                line-height: 16px;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
            &-subtitle {
                color: #9d9d9e;
                font-size: 11px;
                line-height: 13px;
                margin: 0;
                margin-top: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
        }
        &__btn {
            width: 100%;
            padding: 0;
            border: none;
            background-color: transparent;
            cursor: pointer;
        }
        &__footer {
            gap: 0 5px;
            display: flex;
            flex-wrap: wrap;

            margin-top: 2px;
            font-size: 12px;
        }
        &__files {
            gap: 5px;
            display: flex;
            flex-wrap: wrap;
        }
        &__file {
            max-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
</style>

<style>
    strong {
        color: #000;
    }
</style>
