var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-submenu',{class:['el-submenu-materials', ("el-submenu--" + _vm.mode)],attrs:{"index":("" + _vm.isIndex),"disabled":_vm.materialsList.length === 0}},[_c('template',{slot:"title"},[_c('nav-link',{staticClass:"sidebar-link--title",attrs:{"data":_vm.isData,"trigger-add":{ visible: _vm.isAdmin || _vm.isEditorMaterial, to: ("/knowledge/add/" + (_vm.isData.id)) }},on:{"add":_vm.onAddMaterial}})],1),_c('el-menu-item-group',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.isLoaded),expression:"!isLoaded"}]},[_c('div',{staticClass:"sidebar__nav-loading"},[_c('el-tree',{staticClass:"el-submenu-materials__tree",attrs:{"props":_vm.treeProps,"data":_vm.treeDefaultMaterialArray,"load":_vm.treeDefaultLoadNode,"lazy":true,"node-key":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('nav-link',{attrs:{"data":Object.assign(
                        {
                            name: data.title,
                            link: data.link,
                            emoji: data.emoji
                        }
                    ),"tree-node":node}})}}])}),_c('el-tree',{ref:"tree",staticClass:"el-submenu-materials__tree",attrs:{"props":_vm.treeProps,"data":_vm.treeDynamicMaterialArray.current,"load":_vm.treeDynamicLoadNode,"lazy":true,"draggable":_vm.isAdmin,"default-expanded-keys":_vm.treeKeySet,"auto-expand-parent":false,"allow-drop":_vm.TreeAllowDrop,"allow-drag":_vm.TreeAllowDrag,"node-key":"id"},on:{"node-drag-start":_vm.onTreeHandleDragStart,"node-drag-over":_vm.onTreeHandleDragOver,"node-drop":_vm.onTreeHandleDrop},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var node = ref.node;
                    var data = ref.data;
return _c('nav-link',{attrs:{"data":Object.assign(
                        {
                            name: data.title,
                            link: data.link || ("/knowledge/article/" + (data.id)),
                            is_type: 'material'
                        },
                        data
                    ),"draggable":_vm.isAdmin,"mode-type":_vm.mode,"tree-node":node,"trigger-add":{ visible: _vm.isAdmin || _vm.isEditorMaterial, to: ("/knowledge/add/" + (data.id)) }},on:{"changeIcon":_vm.onOpenPicker,"changeParent":function (ref) {
                                        var newParent = ref.newParent;
                                        var oldParent = ref.oldParent;

                                        return _vm.onChangeParent({newParentId: newParent, oldParentId: oldParent, node: node, data: data});
                    },"add":function (dataAdd) { return _vm.onAddMaterial(dataAdd, { node: node, data: data }); }}})}}])})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }