<template>
    <div class="post-editor">
        <bubble-menu
            v-if="editor"
            :editor="editor"
            :tippy-options="{ duration: 100 }"
            class="post-editor__menu"
        >
            <button
                type="button"
                title="Сделать текст жирным"
                :class="{ 'is-active': editor.isActive('bold') }"
                @click="editor.chain().focus().toggleBold().run()"
            >
                Жирный
            </button>

            <button
                type="button"
                title="Сделать текст курсивом"
                :class="{ 'is-active': editor.isActive('italic') }"
                @click="editor.chain().focus().toggleItalic().run()"
            >
                Курсив
            </button>

            <button
                type="button"
                title="Зачеркнуть текст"
                :class="{ 'is-active': editor.isActive('strike') }"
                @click="editor.chain().focus().toggleStrike().run()"
            >
                Зачёркнутый
            </button>

            <button
                type="button"
                :title="editor.isActive('link') ? 'Изменить у текста ссылку' : 'Добавить к тексту ссылку'"
                :class="{ 'is-active': editor.isActive('link') }"
                @click="onSetLink"
            >
                {{ editor.isActive('link') ? 'Изменить ссылку' : 'Вставить ссылку' }}
            </button>
        </bubble-menu>

        <editor-content :editor="editor"/>
    </div>
</template>

<script>
    import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
    import StarterKit from '@tiptap/starter-kit'
    import Link from '@tiptap/extension-link'
    import Placeholder from '@tiptap/extension-placeholder'

    export default {
        name: 'PostTextEditor',
        components: {
            EditorContent,
            BubbleMenu
        },

        props: {
            isContent: {
                type: String,
                default: ''
            },

            isPlaceholder: {
                type: String,
                default: 'Что у вас нового?'
            },

            isClear: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            isClear(newValue) {
                if (newValue === true) {
                    this.editor.commands.clearContent();
                }
            }
        },

        data() {
            return {
                editor: null,
                editorContent: this.isContent,
                editorExtensions: [
                    StarterKit.configure({
                        heading: false,
                        bulletList: false,
                        codeBlock: false,
                        hardBreak: false,
                        horizontalRule: false,
                        listItem: false,
                        orderedList: false
                    }),
                    Link.configure({
                        validate: href => /^https?:\/\//.test(href)
                    }),
                    Placeholder.configure({
                        placeholder: this.isPlaceholder
                    })
                ]
            }
        },

        mounted() {
            this.editor = new Editor({
                extensions: this.editorExtensions,
                content: this.editorContent,
                editorProps: {
                    attributes: {
                        class: 'post-editor__content'
                    }
                },
                onUpdate: () => {
                    let result = this.editor.getHTML();

                    if (this.editor.isEmpty) {
                        result = '';
                    }

                    this.$emit('input', result);
                },

                onFocus: () => {
                    this.$emit('focus', this.editor);
                },

                onBlur: () => {
                    this.$emit('blur', this.editor);
                }
            })
        },

        methods: {
            onSetLink() {
                const previousUrl = this.editor.getAttributes('link').href;
                const url = window.prompt('URL', previousUrl);

                // cancelled
                if (url === null) {
                    return;
                }

                // empty
                if (url === '') {
                    this.editor
                        .chain()
                        .focus()
                        .extendMarkRange('link')
                        .unsetLink()
                        .run();

                    return;
                }

                // update link
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: url })
                    .run();
            }
        }
    }
</script>

<style lang="scss">
    @import '#sass/_variables.sass';

    $color-menu: #373a45;

    .post-editor {
        &__content {
            outline: none;
            .is-editor {
                &-empty {
                    position: relative;
                    &:before {
                        content: attr(data-placeholder);
                        color: #a4aebd;
                        position: absolute;
                    }
                }
            }

            a {
                text-decoration: underline;
            }
        }

        &__menu {
            display: flex;
            padding: 5px 7px;
            border-radius: 5px;
            background-color: $color-menu;
            box-shadow: $box-shadow-primary;
            button {
                color: #fff;
                font-size: 14px;
                cursor: pointer;
                padding: 0 5px;
                border-radius: 5px;
                border: none;
                background-color: transparent;
                transition: all $ease;
                &:hover {
                    opacity: .9;
                }

                &:hover,
                &.is-active {
                    background-color: #5e6479;
                }

                & + button {
                    margin-left: 3px;
                }
            }
        }
    }
</style>
