import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
const getter = (source, getter) => source.getters[getter];

const { push } = Router.prototype;
const { isNavigationFailure, NavigationFailureType } = Router;

Router.prototype.push = function(location) {
    return push.call(this, location).catch(error => {
        if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
            throw Error(error);
        }
    });
};

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        /* Авторизация */
        {
            name: 'AutoLogin',
            path: '/auth/login',
            component: () => import('@/views/auth/AutoLogin.vue'),
            meta: {
                requiresAuth: false,
                title: 'WikiWorks'
            }
        },
        {
            name: 'Login',
            path: '/login',
            component: () => import('@/views/auth/Login.vue'),
            meta: {
                requiresAuth: false,
                title: 'Войти'
            }
        },
        {
            name: 'PasswordReset',
            path: '/password_reset',
            component: () => import('@/views/auth/PasswordReset.vue'),
            meta: {
                requiresAuth: false,
                title: 'Восстановление пароля'
            }
        },
        {
            name: 'NewPassword',
            path: '/auth/confirm_query/:hash',
            component: () => import('@/views/auth/NewPassword.vue'),
            meta: {
                requiresAuth: false,
                title: 'Восстановление пароля'
            }
        },
        {
            name: 'FailConfirm',
            path: '/auth/fail_confirm_query/',
            component: () => import('@/views/auth/FailConfirm.vue'),
            meta: {
                requiresAuth: false,
                title: 'Восстановление пароля'
            }
        },
        /* Профиль */
        {
            name: 'Profile',
            path: '/auth/profile',
            component: () => import('@/views/user/Profile'),
            children: [
                {
                    path: ':id',
                    component: () => import('@/views/user/Profile'),
                    meta: {
                        requiresAuth: true,
                        title: ''
                    }
                }
            ],
            meta: {
                requiresAuth: true,
                title: ''
            }
        },
        {
            name: 'ProfileEdit',
            path: '/auth/profile/edit/:id',
            component: () => import('@/views/user/ProfileEdit'),
            meta: {
                requiresAuth: true,
                title: ''
            }
        },
        /* Дашборд */
        {
            name: 'Dashboard',
            path: '/',
            component: () => import('@/views/menu/dashboard/Dashboard.vue'),
            meta: {
                requiresAuth: true,
                title: ''
            }
        },
        /* Компания */
        {
            name: 'Structure',
            path: '/structure',
            component: () => import('@/views/menu/company/Structure.vue'),
            meta: {
                requiresAuth: true,
                title: 'Структура компании'
            }
        },
        {
            name: 'Staff',
            path: '/staff',
            component: () => import('@/views/menu/company/Staff.vue'),
            meta: {
                requiresAuth: true,
                title: 'Сотрудники'
            }
        },
        {
            name: 'Department',
            path: '/department/:department_id',
            component: () => import('@/views/menu/company/Department.vue'),
            meta: {
                requiresAuth: true,
                title: 'Отдел'
            }
        },
        {
            name: 'DepartmentChange',
            path: '/department/:department_id/edit',
            component: () => import('@/views/menu/company/DepartmentChange.vue'),
            meta: {
                requiresAuth: true,
                title: 'Редактирвать отдел'
            }
        },
        /* База знаний 2.0 */
        {
            name: 'KnowledgeBase',
            path: '/knowledge',
            component: () => import('@/views/knowledge_base/KnowledgeBase'),
            meta: {
                requiresAuth: true,
                title: ''
            }
        },
        {
            name: 'KnowledgeBaseChange',
            path: '/knowledge/edit',
            component: () => import('@/views/knowledge_base/KnowledgeBaseChange'),
            meta: {
                requiresAuth: true,
                title: ''
            }
        },
        {
            name: 'Material',
            path: '/knowledge/article/:material_id',
            component: () => import('@/views/knowledge_base/Material'),
            meta: {
                requiresAuth: true,
                title: ''
            }
        },
        {
            name: 'MaterialReview',
            path: '/material_review/:material_id',
            component: () => import('@/views/knowledge_base/MaterialReview'),
            meta: {
                requiresAuth: false,
                title: ''
            }
        },
        {
            name: 'MaterialAdd',
            path: '/knowledge/add/:parent_id',
            component: () => import('@/views/knowledge_base/MaterialChange'),
            meta: {
                requiresAuth: true,
                title: 'Добавить материал'
            }
        },
        {
            name: 'MaterialEdit',
            path: '/knowledge/edit/:material_id',
            component: () => import('@/views/knowledge_base/MaterialChange'),
            meta: {
                requiresAuth: true,
                title: 'Редактировать материал'
            }
        },
        {
            name: 'MaterialOld',
            path: '/knowledge/old',
            component: () => import('@/views/knowledge_base/OldMaterials'),
            meta: {
                requiresAuth: true,
                title: 'Устаревшие материалы'
            }
        },
        {
            name: 'MaterialRecommended',
            path: '/knowledge/recommended',
            component: () => import('@/views/knowledge_base/RecommendedMaterials'),
            meta: {
                requiresAuth: true,
                title: 'Рекомендуемые материалы'
            }
        },
        {
            name: 'FavouritesMaterials',
            path: '/knowledge/favourites',
            component: () =>
                import('@/views/knowledge_base/FavouritesMaterials'),
            meta: {
                requiresAuth: true,
                title: 'Избранные материалы'
            }
        },
        /* Сравнение версий */
        {
            name: 'MaterialDiff',
            path: '/knowledge/diff/:id',
            component: () => import('@/views/menu/section/MaterialDiff'),
            meta: {
                requiresAuth: true,
                title: 'Сравнение версий'
            }
        },
        /* Каталог регламентологов */
        {
            name: 'Regulator',
            path: '/regulator',
            component: () => import('@/views/menu/regulator/Regulator'),
            meta: {
                requiresAuth: true,
                title: 'Каталог регламентологов'
            }
        },
        /* Документы */
        {
            name: 'Documents',
            path: '/documents',
            component: () => import('@/views/menu/documents/Documents'),
            meta: {
                requiresAuth: true,
                title: 'Документы'
            }
        },
        {
            name: 'DocumentAdd',
            path: '/documents/add',
            component: () => import('@/views/menu/documents/DocumentAdd'),
            meta: {
                requiresAuth: true,
                title: 'Добавить документ'
            },
            query: {
                rubricId: String
            }
        },
        {
            name: 'DocumentEdit',
            path: '/documents/edit/:id',
            component: () => import('@/views/menu/documents/DocumentEdit'),
            meta: {
                requiresAuth: true,
                title: 'Редактировать документ'
            }
        },
        /* Курсы (образование) */
        {
            name: 'Courses',
            path: '/courses',
            component: () => import('@/views/menu/course/CourseList'),
            meta: {
                requiresAuth: true,
                title: 'Курсы'
            }
        },
        {
            name: 'CourseEdit',
            path: '/courses/:course_id/edit',
            component: () => import('@/views/menu/course/CourseChange'),
            meta: {
                requiresAuth: true,
                title: 'Добавить курс'
            }
        },
        {
            name: 'CourseAdd',
            path: '/courses/add/:status',
            component: () => import('@/views/menu/course/CourseChange'),
            meta: {
                requiresAuth: true,
                title: 'Добавить курс'
            }
        },
        {
            name: 'TestAdd',
            path: '/courses/:course_id/:module_id/test/add',
            component: () => import('@/views/menu/course/TestChange'),
            meta: {
                requiresAuth: true,
                title: 'Добавить тест'
            }
        },
        {
            name: 'TestChange',
            path: '/courses/:course_id/:module_id/test/:test_id/edit',
            component: () => import('@/views/menu/course/TestChange'),
            meta: {
                requiresAuth: true,
                title: 'Редактировать тест'
            }
        },
        {
            name: 'CourseFinish',
            path: '/courses/:course_id/finish',
            component: () => import('@/views/menu/course/CourseFinish'),
            meta: {
                requiresAuth: true,
                title:
                    'Поздравляем, вы успешно прошли тестирование и закончили курс!'
            }
        },
        {
            name: 'CourseTestFail',
            path: '/courses/:course_id/test/:test_id/fail',
            component: () => import('@/views/menu/course/TestFail'),
            meta: {
                requiresAuth: true,
                title:
                    'Поздравляем, вы успешно прошли тестирование и закончили курс!'
            }
        },
        {
            name: 'CourseDetail',
            path: '/courses/:course_id',
            component: () => import('@/views/menu/course/CourseDetail'),
            meta: {
                requiresAuth: true,
                title: 'Курс'
            }
        },
        {
            name: 'CourseResults',
            path: '/courses/:course_id/results',
            component: () => import('@/views/menu/course/CourseResults'),
            meta: {
                requiresAuth: true,
                title: 'Результаты курса'
            }
        },
        {
            name: 'CourseTest',
            path: '/courses/:course_id/test/:test_id',
            component: () => import('@/views/menu/course/Test'),
            meta: {
                requiresAuth: true,
                title: 'Тестирование'
            }
        },
        {
            name: 'Lesson',
            path: '/courses/:course_id/lesson/:lesson_id',
            component: () => import('@/views/menu/course/Lesson'),
            meta: {
                requiresAuth: true,
                title: 'Урок'
            }
        },
        {
            name: 'LessonEdit',
            path: '/courses/:course_id/:module_id/lesson/:lesson_id/edit',
            component: () => import('@/views/menu/course/LessonChange'),
            meta: {
                requiresAuth: true,
                title: 'Редактировать урок'
            }
        },
        {
            name: 'LessonAdd',
            path: '/courses/:course_id/:module_id/lesson/add',
            component: () => import('@/views/menu/course/LessonChange'),
            meta: {
                requiresAuth: true,
                title: 'Добавить урок'
            }
        },
        /* Тестирование */
        {
            name: 'UserTesting',
            path: '/testing',
            component: () => import('@/views/menu/testing/UserTesting'),
            meta: {
                requiresAuth: true,
                title: 'Тестирование'
            }
        },
        {
            name: 'TestingSettings',
            path: '/testing/settings',
            component: () => import('@/views/menu/testing/TestingSettings'),
            meta: {
                requiresAuth: true,
                title: 'Настройки тестирования'
            }
        },
        {
            name: 'TestingResults',
            path: '/testing/results',
            component: () => import('@/views/menu/testing/TestingResults'),
            meta: {
                requiresAuth: true,
                title: 'Результаты тестирования'
            }
        },
        /* Идеи */
        {
            name: 'Idea',
            path: '/idea',
            component: () => import('@/views/menu/ideas/IdeasContent'),
            meta: {
                requiresAuth: true,
                title: 'Идеи'
            }
        },
        {
            name: 'IdeaPage',
            path: '/idea/:id',
            component: () => import('@/views/menu/ideas/IdeaPage'),
            meta: {
                requiresAuth: true,
                title: ''
            }
        },
        /* Настройки */
        {
            name: 'Settings',
            path: '/settings',
            component: () => import('@/views/menu/settings/Settings'),
            meta: {
                requiresAuth: true,
                title: 'Настройки'
            }
        },
        /* Счета */
        {
            name: 'Invoice',
            path: '/settings/invoice',
            component: () => import('@/views/menu/settings/Invoice'),
            meta: {
                requiresAuth: true,
                title: 'Счета'
            }
        },
        /* Акт */
        {
            name: 'Act',
            path: '/settings/act',
            component: () => import('@/views/menu/settings/Act'),
            meta: {
                requiresAuth: true,
                title: 'Акты'
            }
        },
        /* Оплата */
        {
            name: 'BalanceReplenishment',
            path: '/billing/balance_replenishment',
            component: () =>
                import('@/views/menu/billing/BalanceReplenishment'),
            meta: {
                requiresAuth: true,
                title: 'Пополнение баланса'
            }
        },
        /* Поиск */
        {
            name: 'SearchView',
            path: '/search',
            component: () => import('@/views/menu/SearchView'),
            meta: {
                requiresAuth: true,
                title: 'Результаты поиска'
            }
        },
        /* Мастер настройки */
        {
            name: 'WizardStarting',
            path: '/start',
            component: () => import('@/views/wizard_starting/WizardStarting'),
            meta: {
                requiresAuth: true,
                title: 'Мастер настройки портала'
            }
        },
        // {
        //     name: 'EditTree',
        //     path: '/edit-tree',
        //     component: () => import('@/views/EditTree'),
        //     meta: {
        //         requiresAuth: true,
        //         title: 'Редактирование дерево файлов'
        //     }
        // },
        /* 403 */
        {
            name: 'error403',
            path: '/403',
            component: () => import('@/views/errors/403.vue'),
            meta: {
                requiresAuth: false,
                isError: true,
                title: '403'
            }
        },
        /* 500 */
        {
            name: 'error500',
            path: '/500',
            component: () => import('@/views/errors/500.vue'),
            meta: {
                requiresAuth: false,
                isError: true,
                title: '500'
            }
        },
        /* 404 */
        {
            name: 'error404',
            path: '*',
            component: () => import('@/views/errors/404.vue'),
            meta: {
                requiresAuth: false,
                isError: true,
                title: '404'
            }
        }
    ],

    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    if (from.name) document.body.classList.remove(`page-${from.name.toLowerCase()}`);
    if (to.name) document.body.classList.add(`page-${to.name.toLowerCase()}`);

    const isAuthorized = localStorage.getItem('isAuthorized');

    // Если пользователь авторизован!
    if (isAuthorized) {
        // Страницы на которые не должен попасть авторизованный пользователь!
        if (
            to.name === 'Login' ||
            to.name === 'PasswordReset'
        ) {
            next({ path: '/' });
        }
    }

    // Если требуется авторизация
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (isAuthorized) {
            next();
        // авторизация отсутствует!
        } else {
            store.commit('url_login/setUrlLogin', { url: from.fullPath });
            console.log(store.getters['url_login/getUrlLogin']);
            console.info('beforeEach - login', isAuthorized);
            next({ name: 'Login' });
        }
    } else {
        next();
    }
});

export default router;
