<template>
    <div class="line-picker">
        <div class="line-picker__container">
            <button
                type="button"
                class="line-picker__button"
                :class="{
                    active: item.active
                }"
                @click="item.onClick()"
                v-for="(item, index) in items"
                :key="item.name + index"
            >
                <span class="line-picker__button-name">{{ item.name }}</span>
                {{ item.count }}
            </button>
        </div>
        <hr class="line-picker-line" />
    </div>
</template>

<script>
    export default {
        props: {
            items: {
                type: Array,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .line-picker {
        display: flex;
        flex-direction: column;
        &__container {
            display: flex;
            overflow-y: hidden;
            overflow-x: auto;
            & > .line-picker__button + .line-picker__button {
                margin-left: 32px;
            }
        }
        &__button {
            padding: 0 0 15px;
            border: none;
            font: inherit;
            color: #a4aebd;
            text-transform: uppercase;
            background-color: transparent;
            cursor: pointer;
            font-weight: 600;
            font-size: 13px;
            line-height: 23px;
            white-space: nowrap;
            border-bottom: 3px solid transparent;
            transition: border-color 200ms ease;
            &-name {
                transition: color 200ms ease;
                margin-right: 5px;
            }
            &:hover {
                .line-picker__button-name {
                    color: #798392;
                }
            }
            &.active {
                border-color: #fbc04f;
                .line-picker__button-name {
                    color: #000;
                }
            }
        }
        &-line {
            margin: 0;
        }
    }
</style>
