<template>
    <div>
        <div v-if="isLoaded" class="modal-dialog modal-dialog--access block block-mode-loading-refresh">
            <div class="modal-content">
                <div class="modal-header modal-header_no-border">
                    <h3 class="block-title">{{title}}</h3>
                    <label class="material-toggle">
                        <input
                            v-if="rubricID"
                            v-model="form.parentRight"
                            @change="copyParentPermission"
                            type="checkbox"
                            class="material-toggle__input"
                        >
                        <input
                            v-else
                            v-model="form.access_all"
                            type="checkbox"
                            class="material-toggle__input"
                        >
                        <span class="material-toggle__icon"></span>
                        <span class="material-toggle__text">{{ checkbox }}</span>
                    </label>

                    <button type="button" class="btn-block-option close-modal" @click="$modal.hide('ModalBindingUpdate')">×</button>
                </div>
                <div class="block-content content modal-staff">
                    <div class="workers__desc">
                        {{ text }}
                    </div>
                    <div
                        class="row"
                        v-bind:class="{ 'area_lock': form.access_all || form.parentRight }"
                    >
                        <div class="col-lg-6 workers">
                            <div class="workers__col">
                                <div class="workers__head">
                                    <ul class="material-header__nav nav">
                                        <li class="nav-item">
                                            <a id="vue-access-department-tab"
                                               @click="data.isVisibleTab=true"
                                               v-bind:class="{active: data.isVisibleTab}"
                                            >Общее</a>
                                        </li>
                                        <li class="nav-item" v-if="showUsersTab">
                                            <a id="vue-access-workers-tab"
                                               v-bind:class="{active: !data.isVisibleTab}"
                                               @click="data.isVisibleTab=false"
                                            >Сотрудники</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="workers__body">
                                    <div class="tab-content">
                                        <div class="tab-pane fade" id="vue-access-department"
                                             v-bind:class="{'active show': data.isVisibleTab}">
                                            <div class="workers__search form-group">
                                                <svg width="13" height="13" viewBox="0 0 13 13"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.16406 5.55739C2.16406 3.68324 3.68336 2.16394 5.55751 2.16394C7.43166 2.16394 8.95096 3.68324 8.95096 5.55739C8.95096 7.43154 7.43166 8.95084 5.55751 8.95084C3.68336 8.95084 2.16406 7.43154 2.16406 5.55739ZM5.55751 0.66394C2.85493 0.66394 0.664062 2.85481 0.664062 5.55739C0.664062 8.25997 2.85493 10.4508 5.55751 10.4508C6.63818 10.4508 7.63702 10.1005 8.44669 9.50729L11.5757 12.6364C11.8686 12.9293 12.3435 12.9293 12.6364 12.6364C12.9293 12.3435 12.9293 11.8686 12.6364 11.5757L9.50736 8.44664C10.1006 7.63696 10.451 6.63808 10.451 5.55739C10.451 2.85481 8.26009 0.66394 5.55751 0.66394Z"></path>
                                                </svg>

                                                <input
                                                    class="form-control round"
                                                    type="text"
                                                    placeholder="Поиск"
                                                    v-model="data.searchStructure"
                                                >
                                            </div>
                                            <div class="workers__tree">
                                                <ul class="workers__department department js-vue-department-tree">
                                                    <permission-tree-structure
                                                        v-for="(item) in data.leftTree.structure"
                                                        :key="item.id"
                                                        :item="item"
                                                        :leftTree="true"
                                                        @buildStructTree="clickStructure"
                                                        @buildPositionTree="clickPosition"
                                                    ></permission-tree-structure>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="vue-access-workers"
                                             v-bind:class="{'active show': !data.isVisibleTab}"
                                        >
                                            <div class="workers__search form-group">
                                                <svg width="13" height="13" viewBox="0 0 13 13"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.16406 5.55739C2.16406 3.68324 3.68336 2.16394 5.55751 2.16394C7.43166 2.16394 8.95096 3.68324 8.95096 5.55739C8.95096 7.43154 7.43166 8.95084 5.55751 8.95084C3.68336 8.95084 2.16406 7.43154 2.16406 5.55739ZM5.55751 0.66394C2.85493 0.66394 0.664062 2.85481 0.664062 5.55739C0.664062 8.25997 2.85493 10.4508 5.55751 10.4508C6.63818 10.4508 7.63702 10.1005 8.44669 9.50729L11.5757 12.6364C11.8686 12.9293 12.3435 12.9293 12.6364 12.6364C12.9293 12.3435 12.9293 11.8686 12.6364 11.5757L9.50736 8.44664C10.1006 7.63696 10.451 6.63808 10.451 5.55739C10.451 2.85481 8.26009 0.66394 5.55751 0.66394Z"></path>
                                                </svg>
                                                <input v-model="data.searchUsers" type="text" class="form-control round"
                                                       placeholder="Поиск">
                                            </div>
                                            <div class="workers__tree">
                                                <ul class="workers__department department">
                                                    <li class="department__item open"
                                                        v-for="(item) in filteredUsers">
                                                        <div class="department__row">
                                                            <span
                                                                class="department__row_name">{{ item.full_name }}</span>
                                                            <label>

                                                                <input type="checkbox" class="access_departament"
                                                                       :data-id="'original-worker-' + item.id"
                                                                       :checked="item.checked"
                                                                       @click="leftTreeUsersBuild(item)">
                                                                <div class="department__row_button">
                                                                    <i v-if="item.checked" class="fas fa-check"></i>
                                                                    <i v-else class="fas fa-plus"></i>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 workers workers--second">
                            <form class="workers__col">
                                <div class="workers__head">
                                    <ul class="material-header__nav nav" role="tablist">
                                        <li class="nav-item">
                                            <a id="vue-sel-access-department-tab"
                                               v-bind:class="{active: data.isVisibleTab}"
                                               @click="data.isVisibleTab=true"
                                            >
                                                Общее
                                                <span
                                                    class="nav-item__numb">{{ data.rightTree.structure.length }}</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" v-if="showUsersTab">
                                            <a id="vue-sel-access-workers-tab"
                                               v-bind:class="{active: !data.isVisibleTab}"
                                               @click="data.isVisibleTab=false"
                                            >
                                                Сотрудники
                                                <span class="nav-item__numb">{{ data.rightTree.users.length }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="workers__body">
                                    <div class="tab-content">
                                        <div class="tab-pane fade" id="vue-sel-access-department"
                                             v-bind:class="{'active show': data.isVisibleTab}"
                                             role="tabpanel" aria-labelledby="material-tab">
                                            <div class="workers__tree">
                                                <ul class="workers__department department">
                                                    <permission-tree-structure
                                                       v-for="(item) in data.rightTree.structure"
                                                       :item="item"
                                                       :key="item.id"
                                                       @buildStructTree="clickStructure"
                                                       @buildPositionTree="clickPosition"
                                                    ></permission-tree-structure>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="vue-sel-access-workers" role="tabpanel"
                                             v-bind:class="{'active show': !data.isVisibleTab}"
                                             aria-labelledby="access-tab">
                                            <div class="workers__list">
                                                <ul class="workers__department department">
                                                    <li class="department__item open"
                                                        v-for="(item) in data.rightTree.users">
                                                        <div class="department__row">
                                                            <span class="department__row_name">
                                                                {{ item.full_name }}
                                                            </span>

                                                            <label>
                                                                <input
                                                                    class="access_departament"
                                                                    :data-id="'worker-' + item.id"
                                                                    :checked="item.checked"
                                                                    type="checkbox"
                                                                    @click.prevent="rightTreeUsersBuild(item)"
                                                                >

                                                                <div class="department__row_button">
                                                                    <i v-if="item.checked" class="fas fa-check"></i>
                                                                    <i v-else class="fas fa-plus"></i>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="module-set__footer d-flex justify-content-center">
                        <button type="button" class="v-btn" @click="onSubmits">Сохранить</button>
                        <button class="v-btn v-btn--transparent" @click="$modal.hide('ModalBindingUpdate')">Отменить</button>
                    </div>
                </div>
            </div>
        </div>
        <loading-spinner v-else/>
    </div>
</template>

<script>
    import session from '@/api/session';
    import PermissionTreeStructure from './PermissionTreeStructure';
    import { basicMethods } from './basic_methods';
    import LoadingSpinner from '@/components/LoadingSpinner';

    export default {
        name: 'ModalBindingUpdate',
        mixins: [
            basicMethods
        ],
        props: {
            showUsersTab: {
                required: false,
                default: true
            },
            afterUpdate: {
                type: Function,
                required: false
            },
            permissionSettings: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isLoaded: false,
                form: {
                    access_all: null,
                    parentRight: null
                }
            };
        },
        components: {
            LoadingSpinner,
            PermissionTreeStructure
        },
        async created() {
            // await this.loadRetrieveDefaultData();
            await this.loadDefaultData();
            await this.loadBindingData();
            await this.loadRetrieveBindingData();

            this.data.isVisibleTab = this.data.leftTree.structure.length || false;

            this.form.access_all = this.data.access_all;
            this.form.parentRight = this.data.parentRight;

            if (
                this.rubricID &&
                !this.isMainRubric &&
                !this.data.access_all &&
                this.data.parentRight
            ) {
                this.copyParentPermission();
            }

            this.isLoaded = true;
        },
        methods: {
            onSubmits(e) {
                e.preventDefault();
                const data = this.dataPreparation();

                data.access_all = this.form.access_all;
                data.note.parentRight = this.form.parentRight;
                data.departments_ids = Object.keys(data.structure);

                const url = `/api/v1/permission/?permission_type=assign_document&permission_object_id=${this.permissionObjectID}`;

                session
                    .post(url, data)
                    .then(response => {
                        data.text = response.data.text;
                        this.$modal.hide('ModalBindingUpdate', { data });

                        if (this.afterUpdate) {
                            this.afterUpdate();
                        }
                    })
                    .catch((error) => {
                        console.error('error: ', error);
                    });
            }
        }
    };
</script>

<style lang="scss">
    .v--modal-overlay[data-modal="ModalBindingUpdate"] {
        .v--modal-box {
            padding: 30px;
            overflow-y: scroll;
        }
        .modal-dialog--access .close-modal {
            top: -20px;
            right: -20px;
        }
        .modal-dialog--access.block {
            margin: 0;
        }
    }
    .modal {
        overflow-y: auto;
    }
    .show-permission {
        display: flex;
        background: rgba(0, 0, 0, 0.2);
    }

    .area_lock {
        opacity: 0.5;
        pointer-events: none;
    }

    .display_flex {
        display: flex !important;
    }

    .is_invalid {
        border-color: #ef5350;
    }

    .modal-dialog--access .workers__col{
        height: 100%;
    }

    .modal-dialog--access.block {
        margin: 0 auto;
    }

    @media (max-width: 660px) {.workers__body
        .workers--second {
            margin-top: 20px;
        }
    }

    .workers__tree {
        height: auto;
        min-height: 250px;
    }

    .workers__col {
        height: 100% !important;
    }

</style>
